import React from 'react';
import DialogueTree from './Components/DialogueTree';



function App() {
  return (
    <div >
      <DialogueTree />
       
    </div>
      
  );
}

export default App;
